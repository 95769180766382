<template>
  <div id="app">
      <router-view v-if='routeAlive'></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routeAlive:true,
      switch:{flag:false},
    };
  },
  provide(){
    return{
      reload:this.reload,
      switch:this.switch
    }
  },
  name: "app",
  mounted() {

  },
  methods: {
    reload(flag){
      this.routeAlive = false;
      this.switch.flag = flag;
      this.$nextTick(()=>{
        this.routeAlive = true
      })
    }
  },
  destroyed() {

  }
};
</script>
