import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import utils from '@/container/utils.js'
import config from '@/config/index'
import sweetalert2 from 'vue-sweetalert2'
import axios from '@/libs/axios'
import i18n from '@/lang' 
import store from './store'
import LoadingBar from '@/components/common/loading-bar'
import 'style/style/reset.less'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueSuperSlide from 'vue-superslide'
import VueTinymce from '@packy-tang/vue-tinymce'
import '@/libs/element.js'
Vue.use(VueTinymce)
Vue.use(VueSuperSlide)
const envConfig = config[process.env.NODE_ENV]
Vue.prototype.$envConfig = envConfig
Vue.prototype.$axios = axios(envConfig)
Vue.prototype.$loadingbar = LoadingBar
Vue.use(sweetalert2)
Vue.use(MetaInfo)
Vue.use({install(Vue) {
}})
Vue.prototype.$utils = utils;

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
