export default {
    navbar: {
        dashboard: 'RobotOS平台',
        open: '开放能力',
        platform: {
            title: '平台接入',
            drop_list: [
                { link_name: '应用商店', },
                { link_name: '合作开发', },
                { link_name: '自开发', }
            ]
        },
        dev_doc: '开发者文档',
        doc: {
            title: '文档中心',
            title_en: 'English Version',
            drop_list: [
                { link_name: 'Native开发', },
                { link_name: 'RN开发', },
                { link_name: '语音配置', }
            ]
        },
        help: '帮助和支持',
        example: '示例',
    },
    home: {
        first: {

        }
    }
}