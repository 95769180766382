export default {
    navbar: {
        dashboard: 'RobotOS Platform',
        open: 'open',
        platform: {
            title: 'platform',
            drop_list: [
                { link_name: 'en应用商店'},
                { link_name: 'en合作开发',},
                { link_name: 'en自开发',}
            ]
        },
        example: 'example',
        help: 'help',
        doc: {
            title: 'doc',
            drop_list: [
                { link_name: 'Native',link:'www.baidu.com'},
                { link_name: 'RN',},
                { link_name: 'tts',}
            ]
        }
      },
}