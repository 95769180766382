import axios from 'axios'
import Cookie from 'js-cookie'
import LoadingBar from '@/components/common/loading-bar'
import utils from '@/container/utils.js'
function getToken () {
  return Cookie.get('__cd_vt') || ''
}

function request (options) {
  let lang = utils.getHrefQuerys()['lang']||'cn'
  let defOpt = {
    method: 'get',
    headers: {
      lang
    },
    timeout: 30000
    // withCredentials:true
  }
  let opt = Object.assign({}, defOpt, options)
  if (!options.noDocToken) {
    opt.headers.authorization = getToken()
  }
  return new Promise((resolve, reject) => {
    const instance = axios.create(opt)
    instance.interceptors.request.use(
      config => {
        LoadingBar.start()
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
  
    instance.interceptors.response.use(
      response => {
        let data;
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if(response.data == undefined){
          data = response.request.responseText
        } else{
          data = response.data
        }
        return data
      },
      err => {
        if (err && err.response) {
          switch (err.response.status) {
            case 400:
            err.message = '请求错误'
            break
        
            case 401:
            err.message = '未授权，请登录'
            break
        
            case 403:
            err.message = '拒绝访问'
            break
        
            case 404:
            err.message = `404: ${err.response.config.url} Not Found`
            break
        
            case 500:
            err.message = '服务器内部错误'
            break
        
            case 501:
            err.message = '服务未实现'
            break
        
            case 502:
            err.message = '网关错误'
            break
        
            case 503:
            err.message = '服务不可用'
            break
        
            case 504:
            err.message = '网关超时'
            break
        
            case 505:
            err.message = 'HTTP版本不受支持'
            break
        
            default:
          }
        }
        return Promise.reject(err) // 返回接口返回的错误信息
      }
    )
    //请求处理
    instance(options)
      .then((res) => {
        LoadingBar.finish()
        resolve(res)
        
        return false
      })
      .catch((error) => {
        LoadingBar.error()
        reject(error)
      })
  })
}

export default function (config) {
  function $axios (options) {
    options.apiPrefix = config.baseUrl + config.apiVer
    return request(options)
  }
  $axios.apiPrefix = config.baseUrl + config.apiVer
  // $axios.apiPrefix2 = config.baseUrl2 + config.apiVer
  $axios.get = function (url, params, options) {
    let get_opt = Object.assign({}, options, {
        method: 'get',
        url: this.apiPrefix + url,
        params
      })
    return request(get_opt)
  }
  $axios.post = function (url, params, options) {
    let post_opt = Object.assign({}, options, {
      method: 'post',
      url: this.apiPrefix + url,
      data: params
    })
    return request(post_opt)
  }
  // $axios.$get = function (url, params, options) {
  //     let get_opt = Object.assign({}, options, {
  //         method: 'get',
  //         url: this.apiPrefix2 + url,
  //         params
  //     })
  //     return request(get_opt)
  // }
  return $axios
}
