import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang' 

import { Button, Select ,Menu,Submenu,MenuItem,MenuItemGroup, Switch, Dropdown, DropdownMenu, DropdownItem, Input, Row, Col} from 'element-ui';

[Button, Select,Menu,Submenu,MenuItem,MenuItemGroup, Switch, Dropdown, DropdownMenu, DropdownItem, Input, Row, Col].forEach(i=>{
    Vue.use(i,{
        i18n: (key, value) => i18n.t(key, value)
    })
})
