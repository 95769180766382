export default {
    topLinks:{
        text:{
           test:'https://test-wiki.orionbase.cn',
           beta:'https://wiki.orionbase.cn',
           production:'https://wiki.orionbase.cn', 
        },
        console:{
            test:'https://test-console.orionbase.cn',
            beta:'https://beta-console.orionbase.cn',
            production:'https://console.orionbase.cn', 
        }
    },
    screen6:{
        robot:{
           test:'https://test-jiedai.ainirobot.com/#/',
           production:'https://jiedai.ainirobot.com/#/', 
        },
        orionStar:{
            test:'http://test.data.ainirobot.com/login',
            production:'https://data.ainirobot.com/login', 
         },
         agent:{
            test:'https://test-eo.ainirobot.com/',
            production:'https://eo.ainirobot.com/', 
         }
    }
}