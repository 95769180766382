export default {
  // baseUrl2 服务端接口
  development: {
    // baseUrl: 'http://localhost:8000',
    baseUrl: 'https://test-api.orionbase.cn',
    // baseUrl2: 'https://test-api.orionbase.cn',
    apiVer: '/api/v1',
    obUrl: 'https://test-console.orionbase.cn'
  },
  test: {
    baseUrl: 'https://test-api.orionbase.cn',
    // baseUrl2: 'https://test-api.orionbase.cn',
    apiVer: '/api/v1',
    obUrl: 'https://test-console.orionbase.cn'
  },
  beta: {
    baseUrl: 'https://beta-api.orionbase.cn',
    // baseUrl2: 'http://10.100.58.251:8096',
    apiVer: '/api/v1',
    obUrl: 'https://beta-console.orionbase.cn'
  },
  production: {
    baseUrl: 'https://api.orionbase.cn',
    apiVer: '/api/v1',
    obUrl: 'https://console.orionbase.cn'
  }
}