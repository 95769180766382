
import dataLinks from './dataLinks.js'
let utils = (()=>{
    let env =(first,second) =>{
        let env = process.env.NODE_ENV;
        if(!env)return {};
        let address = null;
        if(first && second){
            address =  dataLinks[first][second]
        }else if(first) {
            address =  dataLinks[first]
        }else{
            return {}
        }
        
        if(!address[env] && env==='beta'){
            env = 'production'
        }else if(env==='development'){
            env = 'test'
        }
        return  address[env]
    }
    let GATracking=(category, click,action)=>{
        window.ga && window.ga("send","event",category, click,action)
    }
    let getHrefQuerys = function (url, key) {
        let query = {}
        let href = url || location.href;
        if (href.indexOf('?') === -1) return key ? '' : query
        let arr = href.split('?')[1]
        if(location.hash){
            arr = arr.replace(location.hash,'')
        }
        let querys = arr.split('&')
        querys.forEach(item => {
          let kv = item.split('=')
          query[kv[0]] = kv[1]
        })
        return key ? query[key] : query
      }
    let deepCopy = (data) => {
        const t = typeOf(data);
        let o;
        if (t === 'array') {
          o = [];
        } else if (t === 'object') {
          o = {};
        } else {
          return data;
        }
        if (t === 'array') {
          for (let i = 0; i < data.length; i++) {
            o.push(deepCopy(data[i]));
          }
        } else if (t === 'object') {
          for (let i in data) {
            o[i] = deepCopy(data[i]);
          }
        }
        return o;
    };
    function typeOf(obj) {
        const toString = Object.prototype.toString;
        const map = {
          '[object Boolean]': 'boolean',
          '[object Number]': 'number',
          '[object String]': 'string',
          '[object Function]': 'function',
          '[object Array]': 'array',
          '[object Date]': 'date',
          '[object RegExp]': 'regExp',
          '[object Undefined]': 'undefined',
          '[object Null]': 'null',
          '[object Object]': 'object'
        };
        return map[toString.call(obj)];
      }
    function flatten(arr) {
        let result = [];
        for(let i = 0; i < arr.length; i++) {
            result = result.concat(arr[i]);
        }
        return result;
    }
    function addClass(el){
        document.querySelectorAll(el).forEach(item=>{
            let arr = []
            if(item && item.classList && item.classList.length>0){
                arr =  [...item.classList]
            }
            arr = arr.map(item=>{
                let arr = null
                arr = item.split('-')
                return arr
            })
            arr = flatten(arr)
            if(!arr.includes('language')){
                item && item.classList && item.classList.add('language-java')
            }
        })
    }
    function throttle(fn,delay){
        let valid = true
        return function() {
            if(!valid){
                return false
            }
            valid = false
            setTimeout(() => {
                fn()
                valid = true;
            }, delay)
        }
    }

    return {
        getHrefQuerys,// 获取浏览器的参数
        env,
        GATracking,
        deepCopy,
        addClass, // 增加代码块类名
        throttle, // 节流函数
    }
})()
export default utils