import Vue from 'vue'
import VueRouter from 'vue-router'
// 由于文档中心 从首页跳转过来没有具体哪个模块哪篇文章，而外部跳转过来可能指定了模块下的文章
// 而且文档中心本身访问url中需要体现出模块和菜单信息，中心内跳转菜单文章也需要更改url，以下代码为屏蔽控制台报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// import main from '@/components/main'
import App from '@/App'
const home = resolve => require(['@/page/home'], resolve)
const doc = resolve => require(['@/page/doc'], resolve)
const open = resolve => require(['@/page/open'], resolve)
const platform = resolve => require(['@/page/platform'], resolve)
const devDoc = resolve => require(['@/page/devDoc'], resolve)
const devDocEn = resolve => require(['@/page/devDocEn'], resolve)
const example = resolve => require(['@/page/example'], resolve)
const help = resolve => require(['@/page/help'], resolve)
const docEditor = resolve => require(['@/page/writer'], resolve)
let env = process.env.NODE_ENV;


const routes = [{
    path: '/',
    component: App,
    children: [{
            path: '/',
            name: 'home',
            meta: {
                name: 'home',
            },
            component: home
        },
        {
            path: '/open',
            name: 'open',
            meta: {
                name: 'open',
            },
            component: open
        },
        {
            path: '/platform',
            name: 'platform',
            meta: {
                name: 'platform',
            },
            component: platform
        },
        {
            path: '/dev_doc',
            name: 'dev_doc',
            meta: {
                name: 'dev_doc',
            },
            component: devDoc
        },
        {
            path: '/dev_doc_en',
            name: 'dev_doc_en',
            meta: {
                name: 'dev_doc_en',
            },
            component: devDocEn
        },
        {
            path: '/example',
            name: 'example',
            meta: {
                name: 'example',
            },
            component: example
        },
        {
            path: '/help',
            name: 'help',
            meta: {
                name: 'help',
            },
            component: help
        },
        {
            path: '/doc',
            name: 'doc',
            meta: {
                name: 'doc',
            },
            component: doc
        },
    ]
}]
if (env !== 'production') {
    routes[0].children.push({
        path: '/writer',
        name: 'writer',
        meta: {
            name: 'writer',
            title: '文档后台'
        },
        component: docEditor
    })
}

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.length < 1) {
        from.name ? next({ name: from.name }) : next('/')
    } else {
        next()
    }
})

router.afterEach((to, from, next) => {
    let path = to && to.fullPath || ''
    let m = to && to.query && to.query.m || ''
    let h = to && to.query && to.query.h || ''
    window.gtag && window.gtag('config', 'G-6NX0C9J608', { 'page_path': path });
    window.gtag && window.gtag('send', 'event', 'category', '模块id', m, h)
});


export default router